<template>
  <el-table
      show-summary
      :summary-method="getAmountSummaries"
      row-key="key"
      :data="data">
    <el-table-column
        cell-class-name="text-truncate"
        :label="$t('datatable.status')"
        width="380"
        align="left">
      <template slot-scope="{ row }">
        <span class="small text-uppercase text-truncate"  v-if="row.status">
          {{ $t('deal.status.' + row.status) }}
        </span>
      </template>

    </el-table-column>

    <el-table-column
      v-for="(sale, index) in activeSales" v-bind:key="`sale-${index}`"
      :label="$options.filters.userCode(users[sale.id])"
      :property="String(sale.id)"
      :width="110"
      align="center"
    >
      <template slot-scope="{ row }">
        <div v-if="row.users[sale.id].registries">
          <report-value-container
              :name="`${row.status}-${sale.id}`"
              :data="row.users[sale.id].registries"/>
        </div>
        <div v-else>
          {{ row.users[sale.id].count }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
        :label="$t('common.total')"
        prop="total"
        align="center">
      <div slot-scope="{ row }">
        {{ row.total }}
      </div>
    </el-table-column>
    <template slot="empty">
      <div v-if="!loading">
        <h3 class="text-uppercase text-muted mb-0 no-results">
          {{ $t('datatable.no_result') }}
        </h3>
      </div>
      <div v-else>
        <octo-loader/>
      </div>
    </template>
  </el-table>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import {mapGetters} from "vuex";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import {endpoints} from "@/routes/endpoints";
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";

export default {
  name: "DashboardDealStatusTable",
  components: {
    ReportValueContainer,
    OctoLoader,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      data: [],
      loading: false
    }
  },
  props: {
    datePickerValue: {
      type: Array,
      default: () => [null, null]
    },
    endpoint: {
      type: String,
      default: endpoints.DASHBOARD_DEALS_STATUS
    }
  },
  watch: {
    datePickerValue: function (val) {
      this.getData(val)
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      sales: 'common/sales',
    }),

    activeSales() {
      return this.$_.filter(this.sales, sales => sales.active)
    }
  },
  methods: {
    getData: function (val) {
      if (!val.hasOwnProperty(0) || !val.hasOwnProperty(1)) {
        return;
      }

      const queryParams = new URLSearchParams({
        'dateFrom': this.$moment(this.datePickerValue?.[0]).format('YYYY-MM-DD'),
        'dateTo': this.$moment(this.datePickerValue?.[1]).format('YYYY-MM-DD')
      });

      this.loading = true;
      this.data = [];

      this.$api.get(this.endpoint + '?' + queryParams.toString())
        .then((resp) => {
          this.data = resp?.data || [];
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        })
    },

    getAmountSummaries: function (param) {
      const {columns, data} = param;
      const sums = [];

      this.$_.each(columns, (column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.total');
          return;
        }
        const values = this.$_.map(data, (item) => {
          return column.property === 'total'
              ? item?.total || 0
              : item?.users?.[column.property]?.count || 0

        })
        sums[index] = this.$_.sum(values);
      })

      return sums;
    }
  }
}
</script>

<style scoped>

</style>
