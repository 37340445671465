<template>
  <div class="row">
    <div class="col-md-12">
      <octo-chart-container title="Dashboard Leads" v-slot="{datePickerValue}">
        <dashboard-lead-status-table  :date-picker-value="datePickerValue" class="mb-4"/>
        <h5 class="text-uppercase text-muted">{{$t('deal.dash.deal_date_in_vs_statuses')}}</h5>
        <dashboard-deal-status-table :date-picker-value="datePickerValue"/>
      </octo-chart-container>
      <call-center-leads-with-deadlines/>
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-category mb-0 text-uppercase">{{ $t('page.lead_in_progress') }}</h5>
          </div>
        </template>
        <div class="row">
          <div class="col col-md-6">
            <base-chart
              :title="$t('chart.call_center_lead_vs_course')"
              :height="350"
              :endpoint="endPoints.CHARTS.callCenterLeadVsCourse"
            />
          </div>
          <div class="col col-md-6">
            <base-chart
              :title="$t('chart.call_center_lead_vs_campaign')"
              :height="350"
              :endpoint="endPoints.CHARTS.callCenterLeadVsCampaign"
            />
          </div>
          <div class="col col-md-6">
            <base-chart
              :title="$t('chart.call_center_lead_vs_provider')"
              :height="350"
              :endpoint="endPoints.CHARTS.callCenterLeadVsProvider"
            />
          </div>
          <div class="col col-md-6">
            <base-chart
              :title="$t('chart.call_center_lead_vs_operator')"
              :height="350"
              :endpoint="endPoints.CHARTS.callCenterLeadVsOperator"
            />
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {endpoints} from "@/routes/endpoints";
import OctoChartContainer from "@/components/octo-chart-container/OctoChartContainer";
import DashboardLeadStatusTable from "@/pages/Leads/components/DashboardLeadStatusTable";
import BaseChart from "@/components/Charts/BaseChart";
import CallCenterLeadsWithDeadlines from "@/pages/Leads/components/CallCenterLeadsWithDeadlines";
import DashboardDealStatusTable from "@/pages/Deals/components/DashboardDealStatusTable";

export default {
  name: "DashboardView",
  components: {
    DashboardDealStatusTable,
    CallCenterLeadsWithDeadlines, BaseChart, DashboardLeadStatusTable, OctoChartContainer},
  data() {
    return {
      endPoints : endpoints
    }
  },
  async mounted() {
    await this.getUsers();
  },
  methods: {
    ...mapActions({
      getUsers: 'common/getUsers'
    }),
  }
}
</script>

<style scoped>

</style>
