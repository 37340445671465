<template>
  <card class="card-chart" no-footer-line>
    <template slot="header">
      <h5 class="card-category mb-0 text-uppercase">
        {{ $t('callcenter.pending_call_center_leads') }}
      </h5>
    </template>
    <div class="container-fluid">
      <octo-loader v-if="isLoading"/>
      <div class="row" v-if="!isLoading">
        <div class="col-12 col-md-4">
          <card class="card-plain" no-footer-line>
            <template slot="header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-category mb-0 text-uppercase">
                  {{ $t('callcenter.dash.private_recalls') }} [ Lead / Deadline ]
                </h5>
                {{ getLeadsCount(callCenterLeadsByStatus.private) }}
              </div>
            </template>
            <ul class="list-group list-group-flush max-lead-card-height">
              <no-data-component
                v-if="callCenterLeadsByStatus.private && callCenterLeadsByStatus.private.length === 0"/>
              <list-group-item-component
                v-for="(callCenterLead, index) in callCenterLeadsByStatus.private"
                v-bind:key="`private-${index}`"
              >
                <template slot="value">
                  <label-theme-component class="small">
                    {{ callCenterLead.deadline | datetime }}
                  </label-theme-component>
                </template>
                <template slot="label">

                  <div class="text-uppercase small text-truncate">
                    {{ callCenterLead | optional('lead.registry.name') }}
                    {{ callCenterLead | optional('lead.registry.surname') }}
                    [ {{ users[callCenterLead.user_id] | userCode }} ]
                  </div>

                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
        <div class="col-12 col-md-4">
          <card class="card-plain" no-footer-line>
            <template slot="header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-category mb-0 text-uppercase">
                  {{ $t('callcenter.dash.public_recalls') }}
                  [ Lead / Deadline ]
                </h5>
                {{ getLeadsCount(callCenterLeadsByStatus.public) }}
              </div>
            </template>
            <ul class="list-group list-group-flush max-lead-card-height">
              <no-data-component v-if="callCenterLeadsByStatus.public && callCenterLeadsByStatus.public.length === 0"/>
              <list-group-item-component
                v-for="(callCenterLead, index) in callCenterLeadsByStatus.public"
                v-bind:key="`public-${index}`"
              >
                <template slot="value">
                  <label-theme-component class="small">
                    {{ callCenterLead.deadline | datetime }}
                  </label-theme-component>
                </template>
                <template slot="label">
                  <div class="text-uppercase small text-truncate">
                    {{ callCenterLead | optional('lead.registry.name') }}
                    {{ callCenterLead | optional('lead.registry.surname') }}
                    [ {{ campaigns[callCenterLead.campaign_id] | optional('name') }} ]
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
        <div class="col-12 col-md-4">
          <card class="card-plain" no-footer-line>
            <template slot="header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-category mb-0 text-uppercase">
                  {{ $t('callcenter.dash.lead_in_progress') }} [ Lead / Operator ]
                </h5>
                {{ getLeadsCount(callCenterLeadsByStatus.processing) }}
              </div>
            </template>
            <ul class="list-group list-group-flush max-lead-card-height">
              <no-data-component
                v-if="callCenterLeadsByStatus.processing && callCenterLeadsByStatus.processing.length === 0"/>
              <list-group-item-component
                v-for="(callCenterLead, index) in callCenterLeadsByStatus.processing"
                v-bind:key="`processing-${index}`"
              >
                <template slot="label">
                  <div class="text-uppercase small text-truncate">
                    {{ callCenterLead | optional('lead.registry.name') }}
                    {{ callCenterLead | optional('lead.registry.surname') }}
                  </div>
                </template>
                <template slot="value">
                  <label-theme-component class="text-uppercase small">
                    {{ users[callCenterLead.user_id] | userCode }}
                  </label-theme-component>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
        <div class="col-12 col-md-4">
          <card class="card-plain" no-footer-line>
            <template slot="header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-category mb-0 text-uppercase">
                  {{ $t('callcenter.dash.unattainable') }} [ Lead / Deadline ]
                </h5>
                {{ getLeadsCount(callCenterLeadsByStatus.unattainable) }}
              </div>
            </template>
            <ul class="list-group list-group-flush max-lead-card-height">
              <no-data-component
                v-if="callCenterLeadsByStatus.unattainable && callCenterLeadsByStatus.unattainable.length === 0"/>
              <list-group-item-component
                v-for="(callCenterLead, index) in callCenterLeadsByStatus.unattainable"
                v-bind:key="`unattainable-${index}`"
              >
                <template slot="value">
                  <label-theme-component class="small">
                    {{ callCenterLead.deadline | datetime }}
                  </label-theme-component>
                </template>
                <template slot="label">
                  <div class="text-uppercase small text-truncate">
                    {{ callCenterLead | optional('lead.registry.name') }}
                    {{ callCenterLead | optional('lead.registry.surname') }}
                    [ {{ campaigns[callCenterLead.campaign_id] | optional('name') }} ]
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
        <div class="col-12 col-md-4">
          <card class="card-plain" no-footer-line>
            <template slot="header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-category mb-0 text-uppercase">
                  {{ $t('callcenter.dash.free') }} [ Lead / {{ $t('fields.date_in') }} ]
                </h5>
                {{ getLeadsCount(callCenterLeadsByStatus.free) }}
              </div>
            </template>
            <ul class="list-group list-group-flush max-lead-card-height">
              <no-data-component v-if="callCenterLeadsByStatus.free && callCenterLeadsByStatus.free.length === 0"/>
              <list-group-item-component
                v-for="(callCenterLead, index) in callCenterLeadsByStatus.free"
                v-bind:key="`free-${index}`"
              >
                <template slot="value">
                  <label-theme-component class="small">
                    {{ callCenterLead.created_at | datetime }}
                  </label-theme-component>
                </template>
                <template slot="label">
                  <div class="text-uppercase small text-truncate">
                    {{ callCenterLead | optional('lead.registry.name') }}
                    {{ callCenterLead | optional('lead.registry.surname') }}
                    [ {{ campaigns[callCenterLead.campaign_id] | optional('name') }} ]
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
        <div class="col-12 col-md-4">
          <card class="card-plain" no-footer-line>
            <template slot="header">
              <h5 class="card-category mb-0 text-uppercase">
                {{ $t('callcenter.leads_by_lead_status_transitions') }}
              </h5>
            </template>
            <ul class="list-group list-group-flush max-lead-card-height">
              <no-data-component
                v-if="callCenterLeadsByStatus.lead_logs && callCenterLeadsByStatus.lead_logs.length === 0"/>
              <list-group-item-component
                v-for="(callCenterLead, index) in callCenterLeadsByStatus.lead_logs"
                v-bind:key="`lead-logs-${index}`"
              >

                <template slot="value">
                  <label-theme-component class="small">
                    {{ callCenterLead.logs_count }}
                  </label-theme-component>
                </template>
                <template slot="label">
                  <div class="text-uppercase small text-truncate">
                    {{ callCenterLead | optional('lead.registry.name') }}
                    {{ callCenterLead | optional('lead.registry.surname') }}
                    [ {{ campaigns[callCenterLead.campaign_id] | optional('name') }} ]
                  </div>
                </template>
              </list-group-item-component>
            </ul>
          </card>
        </div>
      </div>
    </div>
  </card>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import NoDataComponent from "@/components/NoDataComponent";

export default {
  name: "CallCenterLeadsWithDeadlines",
  components: {NoDataComponent, LabelThemeComponent, ListGroupItemComponent, OctoLoader},
  data() {
    return {
      callCenterLeadsByStatus: {
        processing: [],
        private: [],
        public: [],
        unattainable: [],
        free: [],
        lead_logs: [],
      },
      isLoading: false
    }
  },
  mounted() {
    this.isLoading = true;

    this.$api.get(endpoints.DASHBOARD_CALL_CENTER_LEADS_WITH_DEADLINES)
      .then((resp) => {
        this.callCenterLeadsByStatus = resp?.data;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      campaigns: 'common/campaigns',
    }),
  },
  methods: {
    getLeadsCount(leads) {
      return leads?.length || 0;
    }
  }
}
</script>

<style scoped>
.max-lead-card-height {
  max-height: 300px;
  overflow: auto;
}
</style>
