<template>
  <li class="standard-li">
    <slot name="label" :value-class="valueClass">
      <div class="standard-label" :style="{width: labelWidth ? `${labelWidth}px` : 'auto'}">{{ label }}</div>
    </slot>
    <slot name="value" :value-class="valueClass">
      <div :class="valueClass">
        {{ value }}
      </div>
    </slot>
  </li>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ListGroupItemComponent",
  props: {
    label: {
      type: String | Number,
      default: null
    },
    labelWidth: {
      type: Number,
      default: null
    },
    value: {
      type: String | Number,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
    }),
    valueClass: function () {
      switch (this.themeColor) {
        case 'primary':
          return 'text-primary';
        case 'vue':
          return 'text-success';
        case 'green':
          return 'text-success';
        case 'red':
          return 'text-danger';
        case 'orange':
          return 'text-warning';
        case 'blue':
          return 'text-info';
        default:
          return 'text-primary';
      }
    }
  }
}
</script>

<style scoped>

</style>
