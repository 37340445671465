<template>
  <div class="text-center text-muted">
    <h3 class="text-muted text-uppercase mb-0">{{label}}</h3>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
export default {
  name: "NoDataComponent",
  components: {OctoIcon},
  props: {
    label: {
      type: String,
      default: 'No Data'
    }
  }
}
</script>

<style scoped lang="scss">
.iconNoData {
  font-size: 5em;
  line-height: 1em;
  opacity: 0.6;
}
</style>
