<template>
  <span :class="valueClass">
    <slot> </slot>
  </span>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "LabelThemeComponent",
  props: {

  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
    }),
    valueClass: function () {
      switch (this.themeColor) {
        case 'primary':
          return 'text-primary';
        case 'vue':
          return 'text-success';
        case 'green':
          return 'text-success';
        case 'red':
          return 'text-danger';
        case 'orange':
          return 'text-warning';
        case 'blue':
          return 'text-info';
        default:
          return 'text-primary';
      }
    }
  }
}
</script>

<style scoped>

</style>
