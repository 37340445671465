<template>
  <div>
    <div v-if="data && data.length > 0">
      <el-popover trigger="click" placement="left">
          <div class="d-flex list-container">
            <ul v-for="(data, index) in dataChunked" v-bind:key="`chunk-${name}-${index}`"
                class="list-group list-group-flush mx-2">
              <li v-for="(item, index) in data" v-bind:key="`${name}-${index}`"
                  class="list-group-item small text-uppercase py-1 text-nowrap">
                [{{item.id | udid}}] {{ item.name }}
              </li>
            </ul>
          </div>
        <div slot="reference" style="cursor: pointer">{{ data.length ? data.length : '' }}</div>
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportValueContainer",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    dataChunked: function () {
      return this.$_.chunk(this.data, 20)
    }
  }
}
</script>

<style scoped lang="scss">
.list-container {
  max-width: 600px;
  overflow: auto;
}

</style>
